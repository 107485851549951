// Prepare default device configurations to be returned on [1000] request
// NOTE: "f" for SFTP credentials will not be included by default as it contains sensitive information
const defaultDeviceConfigs = {
    e: true,
    d: {
        g: {
            e: true,
            r: 10,
        },
        a: {
            f: 60,
        },
        e: {
            g: false,
            p: true,
            t: true,
        },
    },
    g: {
        e: false,
        t: 9494,
        u: 22335,
    },
    p: true,
    s: 0,
    w: '192.168.2.31',
};

export default JSON.stringify(defaultDeviceConfigs);
